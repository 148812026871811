const fetchWithAuth = async (url, options) => {
  const user = await getCurrentUser();

  const token = await user.getIdToken(true);

  return await $fetch(url, {
    headers: {
      authentication: token,
    },
    ...options,
  });
};

export default fetchWithAuth;
